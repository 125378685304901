import { ThemeProvider } from "@mui/material/styles";
import * as React from "react";
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignIn from "./Screens/Signin";
import SignUp from "./Screens/Signup";
import ChatHome from "./Screens/ChatHome";
import Success from "./Screens/Success";
import * as Constants from './Constants';

function getInitValue () {

  return sessionStorage.getItem("code") || "";

}


function App() {

  const init = getInitValue();

  const [code, setCode] = React.useState(init);
  const [access, setAccess] = React.useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (code == process.env.REACT_APP_ACCESS) {
      setAccess(code);
      sessionStorage.setItem("code", code);
    } else {
      alert("Sorry that access code is invalid!")
    }
  };

  console.log(process.env.REACT_APP_RELEASE);
  if (process.env.REACT_APP_RELEASE != "PRIVATE" || access == process.env.REACT_APP_ACCESS) {
      return (
        <div className="App">
          <BrowserRouter>
            <Routes>
              <Route exact path="/" element={<SignIn />} />
              <Route path="/Signup" element={<SignUp />} />
              <Route path="/chat-home/" element={<ChatHome />} /> 
              <Route path="/chat-home/:receiverId" element={<ChatHome />} /> 
              <Route path="/success" element={<Success />} /> 
            </Routes>
          </BrowserRouter>
        </div>
      );
    } else {
      return (
        <div className="App">
            <ThemeProvider theme={Constants.theme}>
              <title>Success</title>
              <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
              />
              <div style={Constants.bg}></div>
    
              <Paper
                sx={{
                  margin: "auto",
                  marginTop: "20vh",
                  display: "flex",
                  flexDirection: "column",
                  width: "max-content",
                  backgroundColor: "#e2f9f3",
                  borderRadius: 3,
                  padding: "1rem",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{ fontWeight: "bold", p: 0, textAlign: "center" }}
                  component="h2"
                  variant="h2"
                >
                  Hold up!
                </Typography>
                <p>
                  <strong>ERROR 403: Not authorized!</strong>
                </p>
                <p>
                  IncogniTalk has not gone public-access yet!
                </p>
                <p>Please provide the access code to proceed.</p>
                
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  style={{display: "flex", flexDirection: "column"}}
                >
                  <TextField 
                    onChange={(e) => setCode(e.target.value)}
                    name="code" id="outlined-basic" label="Access Code" variant="outlined" />
                  <Button
                      type="submit"
                      onSubmit={handleSubmit}
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Submit
                    </Button>
                    <p>Note: Reloading will cause session to expire</p>
                </Box>
              </Paper>
            </ThemeProvider>
          </div>
      );
    }
}

export default App;
