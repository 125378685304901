import { ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';
import * as Constants from '../Constants';


const Success = () => {

  const navigate = useNavigate();
  return (
    <ThemeProvider theme={Constants.theme}>
      <title>Success</title>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
      <div style={Constants.bg}></div>

      <Paper
        sx={{
          marginTop: "20%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: 20,
          minWidth: "fit-content",
          backgroundColor: "#e2f9f3",
          borderRadius: 10,
          padding: "3rem",
          textAlign: 'center',
        }}
      >
        <Typography
          sx={{ fontWeight: "bold", p: 0, textAlign: "center" }}
          component="h1"
          variant="h1"
        >
          Success
        </Typography>
        <p style={{paddingBottom: 20}}>
          Your account has been created. You do <strong>not</strong> need to confirm your email
          to start using <strong>IncogniTalk</strong>. You can terminate your account any time
          along with your data from the settings.
        </p>
        <Button style={{color:'black', backgroundColor: '#4CDEB4', paddingLeft: 50, paddingRight: 50}} color = 'primary' onClick={() => {
              navigate('/');
            }}>
                Sign in
        </Button>
      </Paper>
    </ThemeProvider>
  );
};

export default Success;
