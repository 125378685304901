import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import { Divider, Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { auth, db } from "./Firebase";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { collection, doc, getDocs, query, setDoc, where } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import "../index.css";
import * as Constants from '../Constants';

const primaryColor = "#2B2B2B"; 
const otherChat = "#bcbcbc";
const textColor = "white";

export default function SignUp() {
  
  const [username, setUsername] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [btnDisabled, setBtnState] = React.useState(true);
  const [modalOpen, setModal] = React.useState(false);
  const [modalText, setModalText] = React.useState("")
  const [fieldInFocus, setFieldInFocus] = React.useState("");
  const [checkBoxState, setCheckBox] = React.useState(false);

  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();

    setBtnState(true);

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      
      await updateProfile(auth.currentUser, {displayName: username,});

      const user = userCredential.user;
      //generating a raandom requestID
      let reqID = Constants.genHexString(6);
      let unique = false;

      while (!unique) {
        //searching to make sure requestID doesn't already exist.
        const qResult = await getDocs(query(collection(db, "users") ,where("requestID", "==", reqID)));
  
        if (qResult.empty) {
          console.log("Unique ID generated!: " + reqID);
          unique = true;
        } else {
          console.log("Found a duplicate! " + reqID + " == " + qResult.docs.at(0).data());
          reqID = Constants.genHexString(6);
        }
      }

      await setDoc(doc(db, "users", user.uid), {
        username: username,
        email: email,
        userId: user.uid,
        timestamp: new Date(),
        requestID: reqID,
      });

      navigate("/Success");

    } catch (error) {
      console.log(error);
      if (error.code == "auth/email-already-in-use") {
        setModalText("It seems this email is already in use. Please try a different one.");
        setModal(true);
      } else if (error.code == "auth/invalid-email") {
        setModalText("Please enter a valid email.");
        setModal(true);
        setFieldInFocus("email");
      } else if (error.code == "auth/internal-error") {
        setModalText("Please enter a valid username and password");
        setModal(true);
        if (username == undefined) setFieldInFocus("username");
        else setFieldInFocus("password")

      } else if (error.code == "auth/weak-password") {
        setModalText("Password is too short. Please enter at least 6 characters.");
        setModal(true);
        setFieldInFocus("password");
      }
    }

    setBtnState(false);
  };

  const handleClose = () => {
    setModal(false);
  }

  const handleCheckBox = () => {
    switch (checkBoxState) {
      case true: setCheckBox(false); setBtnState(true); break;
      case false: setCheckBox(true); setBtnState(false); break;
    }
  }


  return (
    <ThemeProvider theme={Constants.theme}>
      <title>Register</title>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
      <div style={Constants.bg}></div>
      <div style={bounding}>
        <Container style={box} component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: '20%',
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* logo */}
            <Avatar
              sx={{ width: 200, height: 200, p: 4 }}
              alt="logo"
              src={require("../logo.png")}
            />

            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    autoComplete="given-name"
                    name="firstName"
                    focused = {fieldInFocus == "username" ? true : false}
                    required
                    fullWidth
                    id="firstName"
                    label="Username"
                    autoFocus
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    focused = {fieldInFocus == "email" ? true : false}
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    focused = {fieldInFocus == "password" ? true : false}
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Grid>
              </Grid>

              <FormGroup>
                <FormControlLabel control = {
                  <Checkbox checked={checkBoxState} onChange={() => {
                    handleCheckBox();
                  }} />
                } 
                label = {
                    "I agree to the Terms and Conditions"
                } />
              </FormGroup>

              <Button id="sign-up"
                type="submit"
                disabled = {btnDisabled}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign Up
              </Button>
              <Grid container justifyContent="center">
                <Grid item>
                  <Link href="/" variant="body2">
                    Already have an account?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Constants.Copyright sx={{ mt: 5 }} />
        </Container>
      </div>

      {/* Modal */}

      <Dialog
          open={modalOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{style: { backgroundColor: "transparent", borderRadius: 10}}}
        >
          <DialogTitle id="alert-dialog-title" sx={{ backgroundColor: otherChat, color:primaryColor, paddingBottom: 1, textAlign: "center"}}>
            {"Whoops!"}
          </DialogTitle>
          <DialogContent sx={{ backgroundColor: otherChat, padding: "2rem" }}>
          <Divider variant="middle" sx={{ }}/>
            <DialogContentText sx={{ paddingTop: 2, backgroundColor: otherChat, color:primaryColor, textAlign: "center" }} id="alert-dialog-description">
              {modalText}
            </DialogContentText>
          </DialogContent>

        </Dialog>


    </ThemeProvider>
  );
}

const box = {
  backgroundColor: '#e2f9f3',
  padding: '3rem',
  paddingTop: 0,
  borderRadius: '1rem',
};
const bounding = {
  width: "100vw",
  height: "90vh",
  overflow: "hidden",
  margin: 0,
  padding: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent:"center",
}