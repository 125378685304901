import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { Button, Divider, IconButton, Snackbar, Tooltip } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import SendIcon from "@mui/icons-material/Send";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router";
import Typography from "@mui/material/Typography";
import * as Constants from "../Constants";
import { db, auth } from "./Firebase";
import { addDoc, collection, onSnapshot, orderBy, query, getDocs, setDoc, doc, getDoc, where, deleteDoc, or, and } from "firebase/firestore";
import "../index.css";
import LogoutIcon from "@mui/icons-material/Logout";
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { red } from '@mui/material/colors';
import { ThemeProvider } from "@mui/material/styles";
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

let userReqID = "";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const primaryColor = "#2B2B2B"; 
const secondaryColor = "#2B2B2BA0";
const accent = "#2d2d2d";
const selfChat = "#1d9e79";
const otherChat = "#bcbcbc";
const textColor = "white";

async function blockUser(blockID, userID, currentSelectedUser) {


  //checking if blocking or unblocking
  if (currentSelectedUser.blocked) {
    //search up uid
    const ref = collection(db, "users", userID, "requests");
    const q = query(ref, where("from", "==", blockID));
  
    const qResults = await getDocs(q);
    if (!qResults.empty) {
      //update req doc
      await setDoc(
        doc(
          db,
          "users",
          userID,
          "requests",
          qResults.docs.at(0).id
        ),
        {
          blocked: false,
          blockedBy: null,
        },
        {merge : true}
      )
    }
  
    //search up blocked user
    const ref2 = collection(db, "users", blockID, "requests");
    const q2 = query(ref2, where("from", "==", userID));
  
    const qResults2 = await getDocs(q2);
    if (!qResults2.empty) {
      //update req doc
      await setDoc(
        doc(
          db,
          "users",
          blockID,
          "requests",
          qResults2.docs.at(0).id
        ),
        {
          blocked: false,
          blockedBy: null,
        },
        {merge : true}
      )
    }
  
    console.log(`${blockID} is now unblocked!`);
    
  } else {

    //search up uid
    const ref = collection(db, "users", userID, "requests");
    const q = query(ref, where("from", "==", blockID));
  
    const qResults = await getDocs(q);
    if (!qResults.empty) {
      //update req doc
      await setDoc(
        doc(
          db,
          "users",
          userID,
          "requests",
          qResults.docs.at(0).id
        ),
        {
          blocked: true,
          blockedBy: userID,
        },
        {merge : true}
      )
    }
  
    //search up blocked user
    const ref2 = collection(db, "users", blockID, "requests");
    const q2 = query(ref2, where("from", "==", userID));
  
    const qResults2 = await getDocs(q2);
    if (!qResults2.empty) {
      //update req doc
      await setDoc(
        doc(
          db,
          "users",
          blockID,
          "requests",
          qResults2.docs.at(0).id
        ),
        {
          blocked: true,
          blockedBy: userID,
        },
        {merge : true}
      )
    }
  
    console.log(`${blockID} is now blocked!`);
  }

}

async function sendRequest(recipent, sender, field, alertFunction, msg) {

  //no need to validate existence of user as it is done 
  //before the request is even sent out
  const ref = collection(db, "users", recipent.docID, "requests");
  
  //checking if user already sent a request.
  const q = query(
    ref,
    where("from", "==", sender.uid),
  )
  const results = await getDocs(q);

  if (!results?.empty) {

    msg("error");

  } else {
    //clearing search field.
    field("");
  
    //setting alert message
    msg("success");
  
    //sending request
    await setDoc(doc(db, "users", recipent.docID, "requests", recipent.docID), {
      from: sender.uid,
      username: sender.displayName,
      accepted: false,
      rejected: false,
      timestamp: new Date(),
    });

  }

  alertFunction("alert");

}

async function removeUser(user, recipent) {

  //Remove docs of both users from both ends

  //TODO: uncomment this later
  // // removing user side first
  // const ref1 = doc(db, "users", user.uid, "requests", user.uid);
  // deleteDoc(ref1);
  
  // // removing recipent side
  // const ref2 = doc(db, "users", recipent.userId, "requests", recipent.userId);
  // deleteDoc(ref2);

  //removing all messages from both users

  const chatRef = collection(db, "users", user.uid, "chats", recipent.userId, "messages");
  
  const q = query(chatRef);
  const results = await getDocs(q);

  //TODO: This doesn't delete the messages for some reason
  console.log(results.docs);
  if (!results.empty) {

    results.forEach((doc) => {
      console.log(doc.id);
      deleteDoc(db, "users", user.uid, "chats", recipent.userId, "messages", doc.id);
    })

  }

  // const chatRef2 = collection(db, "users", recipent.userId, "chats", user.uid, "messages");
  
  // const q2 = query(chatRef2);
  // const results2 = await getDocs(q2);

  // if (!results2.empty) {

  //   results2.forEach((doc) => {
  //     deleteDoc(db, "users", recipent.userId, "chats", user.uid, "messages", doc.id);
  //   })

  // }


}

function ShowRequests(props) {

  let count = 1;
  const handleInteraction = async (action, i, from) => {

    const q = query(
      collection(db, "users", props.user.uid, "requests"), 
      where("from", "==", props.reqs?.at(i).from)
    );
    const results = await getDocs(q);
    const ref = doc(db, "users", props.user.uid, "requests", results.docs.at(0).id);

    if (action == "accept") {

      console.log(`Accepted ${i}'s Request`);

      //updating user doc
      await setDoc(ref, {
        accepted: true,
      }, {merge : true})
      
      //adding a doc for recipent
      await setDoc(
        doc(db, "users", from, "requests", from), {
          from: props.user.uid,
          username: props.user.displayName,
          accepted: true,
          rejected: false,
          timestamp: new Date(),
      });

    } else {

      //rejected, will not delete the doc.
      //will show all rejected/ignored requests in a separate section.
      console.log(`Rejected ${i}'s Request`);
      await setDoc(ref, {
        rejected: true,
      }, {merge : true})

    }

  }

  if (props.reqs.length != 0)
  return(

    <List>  
      {props.reqs?.map((value, index) => {
        console.log(value);
        const t = Constants.convertTimestamp(value.timestamp);
        return(
          <ListItem sx={{width: "fit-content", color: textColor, }} key={props.user.uid + index}>

            <ListItemAvatar>
              <Avatar alt={`${value.username}`} src={`${value.username}.jpg`}/>
            </ListItemAvatar>
            <ListItemText 
              id = {count++}
              primary= {`${value.username}`}
              secondary= {`Sent on: ${t}`}
              secondaryTypographyProps={{ style: { color: "lightgray", wordWrap: "break-word" } }}
            />
            {/* Buttons */}
            <div style={{paddingLeft:"2rem", display:"flex", gap:"1rem" }}>

              {/* Reject button */}
              <Tooltip title = "Reject">
                <Button variant="outlined" color="attention" sx={{
                  borderWidth: 2,
                  color: "secondary",
                  "&:hover": {
                    borderWidth: 2,
                    backgroundColor: "secondary",
                  },
                  "&:hover .MuiButton-label": {
                    color: "white",
                  },
                }} 
                onClick={() => {
                    handleInteraction("reject", index);
                    props.handleClose("cancel");
                  }}>
                  <CloseIcon/>
                </Button>
              </Tooltip>

              {/* Accept button */}
              <Tooltip title="Accept">
                <Button variant="outlined" sx={{
                  borderWidth: 2, 
                  color: "primary",
                  "&:hover": {
                    borderWidth: 2,
                    backgroundColor: "primary",
                  },
                  "&:hover .MuiButton-label": {
                    color: "white",
                  },
                }} onClick={() => {
                  handleInteraction("accept", index, value.from, props.user);
                  props.handleClose("cancel");
                }
                }> 
                  <CheckIcon/>
                </Button>
              </Tooltip>

            </div>

          </ListItem>
        );})}
    </List>
  );

}

function UsersComponent(props) {

  const handleToggle = (userContext, index) => {

    props.setReceiverData({
      username: userContext.username,
      userId: userContext.from,
    });

    //show message panel
    props.msgPanel("flex");

    //forcing an update
    props.setMobile(false);
    
    //setting current user
    props.setcurrentSelectedUser(userContext);

    props.setActiveIndex(index);

  };

  if (props.users.length != 0)
  return (
    <List dense sx={{width: "100%", maxWidth: 360, bgcolor: secondaryColor}}>

      {props.users?.map((value, index) => {
        const labelId = `checkbox-list-secondary-label-${value}`;


          return (
            <ListItem key={props.currentSelectedUserId+"user-panel" + index} disablePadding>
              <ListItemButton onClick={() => {handleToggle(value, index);}}>
                <ListItemAvatar>
                   
                  <Avatar alt={`${value.username}`} src={`${value.username}.jpg`}/>

                </ListItemAvatar>
                <ListItemText id={labelId} primary={`${value.username}`} />
              </ListItemButton>
            </ListItem>
      );})}
    </List>
  );
  else return(
    <List sx={{color:"gray",textAlign:"center", width: "100%", height:"95%", display: 'flex', alignItems: 'center' }}>
      <ListItemText sx={{textAlign:"center"}} primary="No users to show"></ListItemText>
    </List>
  );
}

function IntroScreen(props) {
  if (props.userPanel)
    return (
      <Paper
        className="shadow"
        id="intro"
        style={{
          // visibility: visibleChat ? "hidden" : "visible",
          display: "flex",
          backgroundColor: primaryColor,
          height: "90vh",
          flexDirection: "column",
          margin: 10,
          marginRight: -15,
          zIndex: 2,
          position: "inherit",
          justifyContent: "center",
          width: "98.5%",
          left: "20rem",
        }}
      >
        <Avatar
          sx={{ width: '8rem', height: '8rem', p: 6, margin: "0 auto" }}
          alt="logo"
          src={require("../logo.png")}
        />

        <Typography
          sx={{ fontWeight: "bold", p: 0, textAlign: "center", color: "white" }}
          component="h3"
          variant="h3"
        >
          Welcome to IncogniTalk
        </Typography>
        <Typography
          sx={{ p: 1, textAlign: "center", color: "white", margin: '0', }}
          component="h6"
          variant="subtitle1"
        >
          Send and receive messages anonymously. <br />
          Add others by sharing your friend code. <br />
          Logout to purge all your messages. <br />
        </Typography>
        <Button id="get-started" style = {{padding: ".5rem 1rem", color: primaryColor, backgroundColor: selfChat, width: "max-content", margin: "0 auto", }}
        onClick={() => {
          //after pressing getting started, kill the intro screen and only show users
          document.getElementById('intro').style.display = "none";
          props.msgPanel("none");
          props.userPanel("block");
        }}
        > Get started </Button> 

      </Paper>
    );
}

function RequestComponent(props) {
  
  if (props.user.username != undefined && props.user.docID != "404" && props.user.docID != "000")
  return (
  <List dense sx={{zIndex: 2, wordBreak:"normal"}}>
      
      <ListItem disablePadding>
        <ListItemButton sx={{width: "max-content"}}
        onClick={() => {
          console.log("clicked on " + props.user.docID)
          sendRequest(props.user, props.loggedUser, props.field, props.alertFunction, props.alertMsgFunction);
        }}>
          <ListItemAvatar>

            <Avatar alt={`${props.user.username}`} src={`${props.user.username}.jpg`}/>

          </ListItemAvatar>
          <ListItemText primary={props.user.username}></ListItemText>
        </ListItemButton>
      </ListItem>
      
    </List>
  );
  else return(null);

}

async function getReqID(user) {

  if (user == undefined) return;

  const dom = document.getElementById("reqIDP");

  await getDoc(doc(db, "users", user?.uid))
  .then(
  (doc) => {
    userReqID = doc.data().requestID;
    dom.innerHTML = "Your Friend Code: " + userReqID;

  }, 
  (error) => {
    console.error(error);
  });
}

async function logout(user) {


  //getting list of users from chats
  const chatList = [];

  const ref = collection(db, "users", user.uid, "chats");
  const usersInChats = await getDocs(ref);

  usersInChats.forEach((doc) => {
    chatList.push(doc.data().username);
  });

  //Removing user side messages.
  const userSideMessagesDeletion = chatList.map(async (e) => {
    
    const messageList = [];
    //getting list of msgIDs of user.
    const messagesRef = collection(db, "users", user.uid, "chats", e, "messages");
    const q = query(messagesRef, where("messageUserId", "==", user.uid), where("removed", "==", false));

    //query for all messages
    const results = await getDocs(q);

    results.forEach((doc) => {
      messageList.push(doc.id);
    });

    const innerPromises = messageList.map(async (msgID) => {

      await setDoc(doc(db, "users", user.uid, "chats", e, "messages", msgID), {
        message: "This message has been removed.",
        removed: true,
      }, {merge : true});
      
    });
    
    return Promise.all(innerPromises);
  });

  await Promise.all(userSideMessagesDeletion);

  //removing messages for other users.
  const otherSideMessagesDeletion = chatList.map(async (e) => {
    
    const messageList = [];
    //getting list of msgIDs of user.
    const messagesRef = collection(db, "users", e, "chats", user.uid, "messages");
    const q = query(messagesRef, where("messageUserId", "==", user.uid), where("removed", "==", false));

    //query for all messages
    const results = await getDocs(q);
    results.forEach((doc) => {
      messageList.push(doc.id);
    })

    const innerPromises = messageList.map(async (msgID) => {

      await setDoc(doc(db, "users", e, "chats", user.uid, "messages", msgID), {
        message: "This message has been removed.",
        removed: true,
      }, {merge : true});
  
    });

    return Promise.all(innerPromises);

  });

  await Promise.all(otherSideMessagesDeletion);

  //user side orphan detection
  const orphans = [];

  //using chatList to go through all chats to look for orphans
  const orphanFinder = chatList.map(async (currentRecipent) => {

    const messagesRef = collection(db, "users", user.uid, "chats", currentRecipent, "messages");
    //contains all the messages in current chat
    const messList = await getDocs(query(messagesRef), orderBy("timestamp", "desc"));

    for (let index = 0; index < messList.size-1; index++) {

      const element = messList.docs.at(index);
      const nextElement = messList.docs.at(index + 1);
      
      if (element.data().removed == true && nextElement.data().removed == true) {
        //adding both elements and incrementing index
        orphans.push({
          msgID: element.id,
          usrID: element.data().messageUserId,
          recID: currentRecipent,
          msgUID: element.data().messageUID
        });
        if (index+1 != messList.size-1) {
          orphans.push({
            msgID: nextElement.id,
            usrID: nextElement.data().messageUserId,
            recID: currentRecipent,
            msgUID: nextElement.data().messageUID
          });
          index++;
        }
      }
    }


  });

  await Promise.all(orphanFinder);
  
  //killing all orphans
  orphans.forEach(async (child) => {
  
    let orphanRef;

    //accessing user side orhpans.
    orphanRef = doc(db, "users", user.uid, "chats", child.recID, "messages", child.msgID);

    //removing doc
    try {
      deleteDoc(orphanRef);
    } catch (error) {
      console.error(error);
    }

    //testing if orphans are properly destroyed.
    // await setDoc(orphanRef, {
    //   message: delMsg + child.msgUID,
    // }, {merge : true});

    //updating on recipent size using msgUID.
    orphanRef = query(collection(db, "users", child.recID, "chats", user.uid, "messages"), where("messageUID", "==", child.msgUID));
    
    const orphanDocs = await getDocs(orphanRef);
    
    if (!orphanDocs.empty) {

      orphanDocs.forEach(async (e) => {
        try {
          deleteDoc(doc(db, "users", child.recID, "chats", user.uid, "messages", e.id));
        } catch (error) {
          console.error(error);
        }
        //testing if orphans are properly destroyed.
        // await setDoc(doc(db, "users", child.recID, "chats", user.uid, "messages", e.id), {
        //   message: delMsg + child.msgUID,
        // }, {merge : true});

      })
    }
  });

  logout(user);
  auth.signOut();

}

async function getUpdate (user) {

  if (user == undefined) return;
  if (user == null) return;

  console.log(user);
  //get user details again



}

export default function Home() {

  //dialog box stuff
  const [open, setOpen] = React.useState(false);
  const [requestsOpen, setReqOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = React.useState(false);

  const [alertMsg, setAlertMsg] = React.useState("success");
  const [userPanel, updateUserPanel] = React.useState("none"); //flip between none and block
  const [msgPanel, updateMsgPanel] = React.useState("none");  //flip between none and flex
  const [mobileMode, setMobile] = React.useState("false");
  const [currentSelectedUser, setcurrentSelectedUser] = React.useState("");
  const [activeIndex, setActiveIndex] = React.useState();


  const handleClickOpen = (e) => {
    switch (e) {
      case "logout":
        setOpen(true);
        break;
      case "notifications":
        setReqOpen(true);
        break;
      case "alert":
        setAlertOpen(true);
        break
      default:
        break;
    }
  };

  const handleClose = (buttonClicked) => {

    if (buttonClicked === "logout") {
      // Perform the action for the "OK" button here
      console.log("logging out");
      logout(user);
    } if (buttonClicked === "notifications") {
      console.log("notifications");
    } else console.log("Cancel");
    
    setOpen(false);
    setReqOpen(false);
    setAlertOpen(false);
    
  };

  const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);

  const [users, setUsers] = useState([]);
  const [receiverData, setReceiverData] = useState(null);
  const [chatMessage, setChatMessage] = useState("");
  const [allMessages, setAllMessages] = useState([]);
  const [allRequests, setAllRequests] = useState([]);
  const [searchResult, initSearchResult] = useState("");
  const [search, ids] = useState("");
  const [notificationCount, setNotificationCount] = useState(0);

  const user = auth.currentUser;
  
  const navigate = useNavigate();

  //this function calculates window size and sets req id + panel
  useEffect(() => {

    //listener for request field
    const div = document.getElementById("request-panel");
    const reqText = document.getElementById("request-text");
    if (search == "") {
      div.style.visibility = "hidden"
      initSearchResult("");
    } else if (searchResult.docID == "404") {
      reqText.innerText = `No user found with code ${search}`;
    } else if (searchResult.docID == "000") {
      reqText.innerText = "Adding yourself as a friend is just sad..";
    } else {
      div.style.visibility = "visible"; 
      reqText.innerText = `Search IncogniTalk for "${search}"`;
    }
    
    //setting reqID
    getReqID(user);

    //sets notification count
    setNotificationCount(allRequests.length);
    
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);
    const started = document.getElementById("intro").style;

    //checking if in mobile mode & start dismissed
    if (!mobileMode && started.display == "none")
      if (windowSize[0] < 1200) {
        //set to mobile if width is under 1200px
        setMobile(true);
        //checking if both chat and user are open, setting user to none
        if (userPanel == "block" && msgPanel == "flex")
          updateUserPanel("none");

      } else updateUserPanel("block");
    else if (windowSize[0] > 1200 && mobileMode) 
      setMobile(false);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
    
  });
  
  //this function populates user panel
  useEffect(() => {
    
    auth.onAuthStateChanged((user) => {
      
      if (user) {
        const q = query(collection(db, "users", user.uid, "requests"),
          where("accepted", "==", true),)
        
        const unsub = onSnapshot(q, (snapshot) => {
          setUsers(snapshot.docs.map((doc) => doc.data()));
          setcurrentSelectedUser(snapshot.docs.at(activeIndex)?.data())
          if (snapshot.docs.at(activeIndex)?.data() == undefined) {
            document.getElementById("chats").style.display = "none";
            document.getElementById("intro").style.display = "flex";
            updateUserPanel("block");
          }

        });
        return unsub;
      }

    });

    //removed this from below
    //, []
    //seems pointless
    //nvm added it back as it prevents it from being refreshed constantly
  }, []);

  //this function populates the messages panel
  useEffect(() => {
    if (receiverData) {

      const unsub = onSnapshot(query(collection(db, "users", user?.uid, "chats", receiverData?.userId, "messages"), orderBy("timestamp")),
        (snapshot) => {
          setAllMessages(snapshot.docs.map((doc) => ({
              id: doc.id,
              messages: doc.data(),
          })));
        }
      );

      return unsub;

    }
    //removed this from this function too.
    // , [receiverData?.userId]
    //no idea what it did.
    //it prevented constant refreshes
  }, [receiverData?.userId]);
  
  //this function gets all the requests from firestore
  useEffect(() => {

    if (users)

    auth.onAuthStateChanged((user) => {

      if (user) {
        const q = query(collection(db, "users", user.uid, "requests"), 
        where("rejected", "==", false), where("accepted", "==", false))

        const unsub = onSnapshot(q, (snapshot) => {
          setAllRequests(snapshot.docs.map((doc) => doc.data()));
        });

        return unsub;
      }

    });

  }, [])

  //this function checks if user is logged
  useEffect(()  => {
    auth.onAuthStateChanged((user) => {

      if (user?.uid == undefined) {
        navigate("/");
      }

    });

  }, []);

  
  const sendMessage = async () => {
    try {
      //checking if user is logged in + a recipent is selected
      if (user && receiverData) {
        //sender side
        //adding user details for the doc (convinient for deletion)
        await setDoc(doc(db, "users", user.uid, "chats", receiverData.userId), {
          username: receiverData.userId,
        });

        //adding a count of users in chat
        //use this when sending requests

        // const chats = await getDocs(collection(db, "users", user.uid, "chats"));
        // console.log("Size of chats: " + chats.size);

        //unique message ID
        const date = new Date();
        const uniqueDateString = date.getDay().toString() + date.getSeconds().toString() + date.getMilliseconds().toString() + (Constants.genHexString(3) + Math.floor(Math.random() * 100)).toString();
        const uniqueMsgId = (user.displayName.slice(0,5) + uniqueDateString)
        console.log(uniqueMsgId);


        //adding message log
        await addDoc(collection(db, "users", user.uid, "chats", receiverData.userId, "messages"), {
          username: user.displayName,
          messageUserId: user.uid,
          messageUID: uniqueMsgId,
          message: chatMessage,
          timestamp: new Date(),
          removed: false,
        });

        //receiver side
        // adding user details for the doc (convinient for deletion)
        await setDoc(doc(db, "users", receiverData.userId, "chats", user.uid), {
          username: user.uid,
        });

        //adding message log
        await addDoc(collection(db, "users", receiverData.userId, "chats", user.uid, "messages"), {
          username: user.displayName,
          messageUID: uniqueMsgId,
          messageUserId: user.uid,
          message: chatMessage,
          timestamp: new Date(),
          removed: false,
        });
      }

    let scrollDown = document.getElementById("chat-window");
		function scrollBottom(element) {
			element.scroll({ top: element.scrollHeight, behavior: "smooth"})
		}
		scrollBottom(scrollDown);

    } catch (error) {
      console.log(error);
    }

  };

  const searchUsers = async () => {

    if (search != userReqID) {

      const q = query(collection(db, "users"), where("requestID", "==", search));
  
      const results = await getDocs(q);
    
      if (results.empty) initSearchResult({docID: "404"});
      // else console.log(search + " Found!");
  
      results.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data().username);
        initSearchResult({
          username: doc.data().username,
          docID: doc.id
        }); 
      });
      return;

    } else {
      initSearchResult({
        docID: "000"
      });

    }
  }
  return (
    <ThemeProvider theme={Constants.theme}>
      <div style={root}>
        <div style={Constants.bg}></div>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
        
        {/* User panel */}
        <Paper id="users" className="shadow" style={{
            color: textColor,
            backgroundColor: secondaryColor,
            display: userPanel,
            height: "90vh",
            minWidth: "17.7rem",
            maxWidth: "18rem",
            margin: 10,
            // marginLeft: chatPanel[userPanel],
            flexDirection: "column"
          }}>

          <div
            style={{
              display: "flex",
              padding: 5,
              justifyContent: "right",
            }}>
            <h4 style={{ margin: 0, color: textColor, padding: ".5rem" }}>
              {"Welcome back, " + user?.displayName}
            <p id="reqIDP"
            style={{fontSize:".rem" ,padding: 0.5, margin:0, fontWeight: 400, display:"block"}}
            >{"Your Friend Code: " + userReqID}</p>
            </h4>
            <Divider sx={{backgroundColor: textColor}} orientation="vertical" variant="middle" flexItem />
            <Button
              style={{ color: selfChat, padding: 0, margin: 0 }}
              color="primary"
              onClick={() => {
                handleClickOpen("logout");
              }}>
              <LogoutIcon style={{ padding: ".1rem" }} />
            </Button>

            {/* confirm dialog box */}
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{style: {backgroundColor: "transparent", borderRadius: 10}}}

            >
              <DialogTitle id="alert-dialog-title" sx={{backgroundColor: otherChat, color:primaryColor }}>
                {"Are you sure you want to logout?"}
              </DialogTitle>
              <DialogContent sx={{backgroundColor: otherChat }}>
                <DialogContentText sx={{backgroundColor: otherChat, color:primaryColor }} id="alert-dialog-description">
                  All of the messages you've sent so far will be deleted once you log out. This process is permanent and irreversible.
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{backgroundColor: otherChat}}>
                <Button sx={{color: primaryColor}} onClick={() => handleClose('cancel')}>No</Button>
                <Button sx={{fontWeight:900, color: red[600], padding:".5rem 1rem"}} onClick={() => handleClose('logout')} autoFocus>
                  Yes, Logout
                </Button>
              </DialogActions>
            </Dialog>


          </div>
            <Paper sx={{ display:'flex', width:'100%', backgroundColor: accent, }}>
              <SearchIcon  sx={{color: 'white', backgroundColor:'inherit', fontSize: 20, margin: 'auto 0', marginLeft: '.5rem', marginRight: '0' }}/>
              <input
                value={search}
                onChange={
                  (e) => {
                    ids(e.target.value)
                  }
                }
                onKeyDown = {(e) => {
                  if (e.key == "Enter" && e.target.value != "") {
                    searchUsers();
                    // ids("");
                  }

                }}
                style={{
                  margin: 0,
                  width: '100%',
                  fontSize: "1rem",
                  backgroundColor: accent,
                  outline: "none",
                  border: "none",
                  padding: "1rem",
                  height: "fit-content",
                  color: "white",
                }}
                type="text"
                placeholder = "Search IncogniTalk"
              />

              {/* User Request Notification Panel */}
              <Tooltip title="View Requests">
                <Button onClick={() => {
                  handleClickOpen("notifications");
                }} sx={{
                  visibility: notificationCount > 0 ? "visible" : "hidden",
                }}>
                  <MarkChatUnreadIcon sx={{
                    // color: notificationCount > 0 ? "red" : "transparent",
                    }}/>
                </Button>
              </Tooltip>

              <Dialog
                open={requestsOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{style: {backgroundColor: "transparent", borderRadius: 10}}}
              >
                <DialogTitle id="alert-dialog-title" sx={{backgroundColor: primaryColor, color:textColor, paddingBottom: 1}}>
                  {"Pending Requests"}
                </DialogTitle>
                <DialogContent sx={{backgroundColor: primaryColor, padding: 0 }}>
                <Divider variant="middle" sx={{backgroundColor: "gray"}}/>
                  <DialogContentText sx={{backgroundColor: primaryColor, color:textColor }} id="alert-dialog-description">
                    <ShowRequests 
                      handleClose = {handleClose}
                      reqs = {allRequests}
                      user = {user}
                    />
                  </DialogContentText>
                </DialogContent>

              </Dialog>
            </Paper>

            {/* Requests Panel */}

            <Paper id="request-panel" sx={{
                position: "absolute", 
                display: "inherit", 
                left: 0, 
                right: 0, 
                top: 115,
                wdith: "max-content",
                height: "fit-content",
                backgroundColor: primaryColor,
                color: "gray",
                zIndex: 2,
                padding: 2,
              }}>
                <div style={{display:"flexbox", flexDirection:"column", wordBreak: "break-word"}}>
                  <p id="request-text" style={{
                    display:"inline-block",
                    width: "fit-content",
                  }}></p>

                  <RequestComponent 
                    user = {searchResult}
                    loggedUser = {user}
                    field = {ids}
                    alertFunction = {handleClickOpen}
                    alertMsgFunction = {setAlertMsg}
                  />
                </div>
            </Paper>
            {}
                  
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleClose}>
              {alertMsg == "success" ? 
                
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                  Request sent successfully!
                </Alert>
                
                :
                
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                  You've already sent this user a request!
                </Alert>
                

              }
              
              
            </Snackbar>
              
          <Divider />

          <div style={{ overflowY: "scroll", height:"100%"}}>
            <UsersComponent
              color="primary"
              setActiveIndex={setActiveIndex}
              users={users}
              setReceiverData={setReceiverData}
              currentSelectedUserId={user?.uid}
              resolution={windowSize[0]}
              msgPanel={updateMsgPanel}
              setMobile={setMobile}
              setcurrentSelectedUser={setcurrentSelectedUser}
            />
          </div>
        </Paper>

        {/* Intro screen */}
        <IntroScreen 
          //passing references
          userPanel={updateUserPanel}
          msgPanel = {updateMsgPanel}
          // updatePanels = {updatePanels}
          windowSize = {windowSize[0]}
        />
              
        {/* Chat panel */}
        <Paper
          id="chats"
          className="shadow"
          style = {{
            display: msgPanel,
            flex: 1,
            height: "90vh",
            margin: 10,
            marginRight: 20,
            flexDirection: "column",
            backgroundColor: secondaryColor,
          }}
        >
          
          <h4
            style={{
              margin: '0',
              padding: 10,
              color: textColor,
              backgroundColor: accent,
            }}>

            <Button id = "show-users" style = {{
                padding: ".5rem 0", 
                margin: 0, 
                color: selfChat, 
                display: mobileMode ? "inline-flex" : "none", 
                position: windowSize[0] < 1200 ? "inherit" : "absolute"
              }}
              onClick = {() => {
                if (userPanel == "block") {
                  updateUserPanel("none");
                } else {
                  updateUserPanel("block");
                }
                // console.log(`Display is now ${userPanel}`);
              }}
              > 
              <MenuIcon /> 
            </Button> 
            
            
            {/* Username on message box */}
            <p style={{ 
                display: "inline", 
                textAlign: "left", 
                marginLeft: ".5rem",
                verticalAlign: "sub",
                fontSize: "1.5rem"
              }}>
              {receiverData ? receiverData.username : user?.displayName}{" "}
            </p>

            {/* Block button */}
            <Button
              sx={{
                display: "inline-flex",
                float:"right",
                fontWeight: 900
              }}

              variant="outlined"
              color="attention"
              onClick={() => {
                
                if (currentSelectedUser?.blockedBy == user?.uid || currentSelectedUser?.blockedBy == undefined) {
                  blockUser(receiverData.userId, user?.uid, currentSelectedUser);
                } else console.log("You're blocked!");

                //update UI
                // setcurrentSelectedUser()
                // getUpdate(users?.at(activeIndex));
                console.log(currentSelectedUser.docID);
                console.log(receiverData);
              }}
            > 
            {
              currentSelectedUser?.blocked ?
                currentSelectedUser?.blockedBy == user?.uid ?
                  `UNBLOCK ${currentSelectedUser?.username}`
                : 
                  `YOU'RE BLOCKED`
              :
              `BLOCK ${currentSelectedUser?.username}`
            }
            </Button>
          </h4>

          <Divider />
          {/* Block Banner Message */}
          <div
            style={
              {
                display: currentSelectedUser?.blocked ? "block" : "none",
                backgroundColor: selfChat,
                textAlign: "center",
                padding: ".5rem",
                color: textColor,
              }
            }
          >
            {
              currentSelectedUser?.blockedBy == user?.uid ?
                "You have blocked this user. "
              :
                "This user has blocked you. "
            }
            <a style={{color: textColor}} onClick={() => {
              removeUser(user, receiverData);

              document.getElementById("chats").style.display = "none";
              updateUserPanel("block");

            }} href="#">Remove this user?</a>
            
          </div>

          <Divider />  
          
          <div id="chat-window" style={messagesDiv}>
            {/* Chat Messages Panel */}

            {allMessages && allMessages.map(({ id, messages }) => {
                return (
                  <div
                    key={id}
                    style = {{
                      padding: "0 2rem",
                      margin: 2,
                      display: "flex",
                      flexDirection:
                        user?.uid == messages.messageUserId
                          ? "row-reverse"
                          : "row",
                    }}
                  >
                    {/* Message bubbles */}
                    <span
                      style={{
                        color:
                          user?.uid == messages.messageUserId ? 
                            "white" : messages.removed ? 
                              "white" :"black",
                        backgroundColor:
                          user?.uid == messages.messageUserId ?
                            messages.removed ? 
                            "transparent" : selfChat : messages.removed ? 
                              "transparent" : otherChat,
                        outline: messages.removed ? 
                          "solid .1rem " + textColor : "none",
                        padding: ".5rem 1rem",
                        borderTopLeftRadius:
                          user?.uid == messages.messageUserId ? 20 : 0,
                        borderTopRightRadius: 20,
                        borderBottomLeftRadius: 20,
                        borderBottomRightRadius:
                        user?.uid == messages.messageUserId ? 0 : 20,
                        width: "fit-content",
                        maxWidth: "40vw",
                        wordWrap: "break-word",
                        fontSize: 16,
                        textAlign: "left",
                      }}
                    >
                      {messages.message}
                    </span>
                    
                  </div>
                );
              })}
          </div>

          <div style={{ 
            width: "100%", 
            display: currentSelectedUser?.blocked ? "none" : "flex",
            flex: 0.08 
          }}>
            <input

              value={chatMessage}
              onChange={(e) => setChatMessage(e.target.value)}
              style={{...input, 
              }}
              type="text"
              placeholder="Aa"
              onKeyDown = {(e) => {
                if (e.key == "Enter" && e.target.value != "") {
                  if (!currentSelectedUser?.blocked) {
                    sendMessage();
                    setChatMessage("");
                  }
                }
              }}
            />
            <IconButton onClick={() => {
              sendMessage();
              setChatMessage("");
            }}>
              <SendIcon style={{ margin: 10, color: selfChat }} />
            </IconButton>
          </div>
        </Paper>

      </div>
    </ThemeProvider>
  );
}

const root = {
  overflow: "hidden",
  display: "flex",
  flexDirection: "row",
  flex: 1,
  width: "100%",
  margin: 0,
  marginTop: ".2%",
  height: "90%",
  
};

const input = {
  fontSize: "1rem",
  backgroundColor: accent,
  marginLeft: ".6rem",
  flex: 1,
  outline: "none",
  borderRadius: 10,
  border: "none",
  padding: "1rem",
  marginTop: ".4rem",
  height: "fit-content",
  overflowY: "scroll",
  color: "white",
};

const messagesDiv = {
  backgroundColor: "#00000000",
  padding: 5,
  display: "flex",
  flexDirection: "column",
  flex: 1,
  maxHeight: "90%",
  overflowY: "scroll",
  
};

