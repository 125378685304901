import * as React from "react";
import { Divider } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./Firebase";
import * as Constants from '../Constants';

import "../index.css";

const primaryColor = "#2B2B2B"; 
const otherChat = "#bcbcbc";
const textColor = "white";


export default function SignIn() {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [modalOpen, setModal] = React.useState(false);
  const [modalText, setModalText] = React.useState("")
  
  const handleClose = () => {
    setModal(false);
  }

  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        // const user = userCredential.user;

        navigate("/chat-home/");
        // ...
      })
      .catch((error) => {

        if (error.code == "auth/user-disabled") {
          setModalText("It seems your account has been banned due to a violation of the ToS.");
          setModal(true);
          return;
        }

        setModalText("Invalid credentials.");
        setModal(true);

      });
  };

  return (
    <div style={bounding}>
      <ThemeProvider theme={Constants.theme}>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
        <title>Login</title>

        <div style={Constants.bg}></div>
        <Container fixed style={box} component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              margin: '0 auto',
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* logo */}
            <Avatar sx={{ width: 200, height: 200, p: 4 }} alt="logo" src={require("../logo.png")} />
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot Password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/Signup" variant="body2">
                    {"Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Constants.Copyright sx={{ mt: 5 }} />
        </Container>


        {/* Modal */}

        <Dialog
          open={modalOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{style: { backgroundColor: "transparent", borderRadius: 10}}}
        >
          <DialogTitle id="alert-dialog-title" sx={{ backgroundColor: otherChat, color:primaryColor, paddingBottom: 1, textAlign: "center"}}>
            {"Whoops!"}
          </DialogTitle>
          <DialogContent sx={{ backgroundColor: otherChat, padding: "2rem" }}>
          <Divider variant="middle" sx={{ }}/>
            <DialogContentText sx={{ paddingTop: 2, backgroundColor: otherChat, color:primaryColor, textAlign: "center" }} id="alert-dialog-description">
              {modalText}
            </DialogContentText>
          </DialogContent>

        </Dialog>

      </ThemeProvider>
    </div>

  );
}

const box = {
  backgroundColor: '#e2f9f3',
  padding: '3rem',
  paddingTop: 0,
  borderRadius: '1rem',
};

const bounding = {
  width: "100vw",
  height: "90vh",
  overflow: "hidden",
  margin: 0,
  padding: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent:"center",

}