import { createTheme } from "@mui/material/styles";
import { green, red } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

export const bg = {
  backgroundImage: "url(" + require("./bg.png") + ")",
  backgroundRepeat: "repeat",
  backgroundSize: "500px",
  position: "absolute",
  top: 0,
  width: "100vw",
  height: "100%",
  maxHeight: "150%",
  zIndex: -1,
  
};

export const theme = createTheme({
  palette: {
    primary: {
      main: "#1d9371",
    },
    attention:
    {
      main: red[700],
    },

  },
});

export function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      <Typography variant="body3">
        <Link color="inherit" href="https://incognitalk.teejay.tech/">
          {" "}
          Terms and Conditions{" "}
          <br />
        </Link>
      </Typography>
      {"Copyright © "}
      <Link color="inherit" href="https://incognitalk.teejay.tech/">
        {" "}
        IncogniTalk
      </Link>{" "}
      {new Date().getFullYear() + " "} | 21204009

    </Typography>
  );
}

export function genHexString(len) {
  let output = '';
  for (let i = 0; i < len; ++i) {
      output += (Math.floor(Math.random() * 16)).toString(16);
  }
  return output;
}

export function convertTimestamp(timestamp) {
	let date = timestamp.toDate();
	const mm = date.getMonth();
	const dd = date.getDate();
	const yyyy = date.getFullYear();
  const h = date.getHours();
  const m = date.getMinutes();
  const s = date.getSeconds();

	date = mm + '/' + dd + '/' + yyyy + " | " + h + ":" + m;
	return date;
}